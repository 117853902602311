<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1>{{ pageTitle }}</h1>
        <div class="d-flex align-items-center pr-2 mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1">
            Ports and Schedules data is automatically updated in certain
            intervals. Click here to update manually (Button)
          </span>
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="/media/svg/icons/General/Update.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->
        <div>
          <v-app>
            <v-card class="mx-auto">
              <div>
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="/media/img/sync_ports2.jpg"
                >
                  <v-card-title
                    style="font-size:20px; text-transform: uppercase;"
                    >Vessels</v-card-title
                  >
                </v-img>
              </div>

              <v-card-subtitle class="pb-0">
                This service updates Vessels and the positions
              </v-card-subtitle>

              <v-card-text class="text--primary">
                <div>
                  Last update:
                  {{
                    states.vessels_done_date ? states.vessels_done_date : "-"
                  }}
                </div>
              </v-card-text>

              <v-card-actions>
                <div
                  v-if="connection_interrupted_vessel"
                  class="error__connection"
                >
                  connection interrupted
                </div>
                <v-btn
                  :loading="!states.vessels_done"
                  depressed
                  color="primary"
                  text
                  @click="syncVesselsButton()"
                >
                  Sync. Vessels now
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="200px"
                src="/media/img/sync_ports3.jpg"
              >
                <v-card-title style="font-size:20px; text-transform: uppercase;"
                  >Schedules (incl. Vessels & Ports)</v-card-title
                >
              </v-img>

              <v-card-subtitle class="pb-0">
                This service updates the following: Schedule, Vessels and Ports
              </v-card-subtitle>

              <v-card-text class="text--primary">
                <div>
                  Last update:
                  {{
                    states.schedules_done_date
                      ? states.schedules_done_date
                      : "-"
                  }}
                </div>
              </v-card-text>

              <v-card-actions>
                <div
                  v-if="connection_interrupted_schedule"
                  class="error__connection"
                >
                  connection interrupted
                </div>
                <v-btn
                  :loading="!states.schedules_done"
                  depressed
                  color="primary"
                  text
                  @click="syncSchedulesButton()"
                >
                  Sync. Schedules now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Synchronization",
  data: function() {
    return {
      pageTitle: "Synchronization",
      connection_interrupted_vessel: false,
      connection_interrupted_schedule: false,
      states: {
        agents_done: 1,
        agents_done_date: null,
        ports_done: 1,
        ports_done_date: null,
        schedules_done: 1,
        schedules_done_date: null,
        vessels_done: 1,
        vessels_done_date: null
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Setting" }]);
  },
  created() {
    ApiService.query("sync/states")
      .then(({ data }) => {
        this.states = data.states;
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
        }
      });
  },
  methods: {
    syncVesselsButton() {
      this.states.vessels_done = false;
      this.connection_interrupted_vessel = false;
      ApiService.query("sync/vessels")
        .then(({ data }) => {
          this.states = data.states;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          } else if (response.status === 403) {
            this.states.vessels_done = true;
            this.connection_interrupted_vessel = true;
          }
        });
    },
    syncSchedulesButton() {
      this.states.schedules_done = false;
      this.connection_interrupted_schedule = false;
      ApiService.query("sync/all")
        .then(({ data }) => {
          this.states = data.states;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          } else if (response.status === 403) {
            this.states.schedules_done = true;
            this.connection_interrupted_schedule = true;
          }
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped>
.v-card {
  margin-top: 25px;
}
.text--primary {
  font-size: 14px;
}
.v-card__actions {
  padding: 15px;
  float: right;
  .v-btn {
    font-size: 14px;
  }
}
.error__connection {
  color: red;
  margin-right: 10px;
}
</style>
